import React from 'react';

const FAQs = [
  {question:"What is docutize.ai?", answer:"docutize.ai is a web-based service to extract data from any PDF document. docutize.ai is powered by AI & GPT to identify all Tables, Text and Key Information. Select the Data you need to Extract. Next docutize.ai will Auto-Select all following pages. Validate the Auto-Selection and Download the Results as .xls, .csv or .txt file."},
{
    question:"What kind of data is docutize.ai for?", answer:"The Free & Pro Plans are designed to work for every PDF document containing machine written Text & Numbers. Enterprise Plans can include any kind of automatic identification up to your needs."
  },
  {
    question:"Does docutize.ai support handwriting?", answer:"Handwriting is not included at this point for Free & Pro Plans. Enterprise Plans can include any kind of automatic identification up to your needs."
  },
  {
    question:"What is OCR, AI Table & key-value-pair, GPT Recognition?",
    answer:"Optical Character Recognition (OCR) is a technology used for scanned documents. Using AI docutize.ai identifies Tables and key-value-pairs (E.g. 'Name: John', 'Name' is the key, and 'John' is the value.). Using GPT Large Language Models we can identify specific values from Texts (e.g. all telephone numbers in Texts)"
    },
{
    question:"What is a key-value pair?", answer:"A key-value pair is a labeled section where the 'key' is the label or name of a piece of information, and the 'value' is the actual information itself. For example, if you see 'Name: John,' 'Name' is the key, and 'John' is the value. The key tells you what type of information it is (a person's name in this case), and the value provides the specific information (the actual name of the person). This concept is used in many everyday documents."
  },        
  {
    question:"Do I need technical knowledge to use docutize.ai?", answer:"No, docutize.ai is designed to be used by everyone. You do not need a technical background to use it. Just upload your documents and you will be guided every step of the process. For more help on how to use the platform see in the help section inside the member's area after uploading."
  },
{
    question:"Is docutize.ai free?", answer:"Yes, docutize.ai is 100% free for up to 20 Pages / Month for individual use or to try out the platform. For more page volume and commerical use we offer Pro and Enterprise Plans. The pricing depends on the number of pages you want to process and also the frequency."
  },
{
    question:"Does docutize.ai require any installation?", answer:"No, docutize.ai runs in your Browser on your Laptop or Desktop Computer. Supported Browsers are Google Chrome, Mozilla Firefox, Apple Safari and Microsoft Edge. You can sign up and start right away."
  },
{
    question:"Can I use docutize.ai on my Mobile Phone or Tablet?", answer:"No, docutize.ai is designed for Laptops and Desktop Computers. Mobile Phones and Tablets are undersized for this use and offer not enough screen space."
  },

{
    question:"How do I get started?", answer:"Just sign up, choose your plan and upload documents. You will be guided each step of the process of extracting data by our platform."
  },
  {
    question:"Can I upgrade/downgrade/cancel my subscription?", answer:"Yes, you can always upgrade, downgrade or cancel your subscription. Go to 'Account' and then to 'My Plan' to change your subscribption in the member's area. Please note: If you cancel your subscription, any remaining subscription credits can only be used until the end of your current billing period."
  },
{
    question:"Is training or customer support available?", answer:"Pro Plans include Email Customer support. Enterprise Plans include Training & Support. The Free Plan does not include any support or training. However, there is a Product Tour, Tutorial Video and platform functionality FAQs inside the member's area to be guided each step of the process."
  },
{
    question:"Is docutize.ai GDPR compliant?", answer:"Yes, docutize.ai is 100% GDPR compliant. For more info please visit our Privacy Policy."
  },

{
    question:"Do scanned PDF documents work too?", answer:"Yes, docutize.ai can process scanned PDF formats as well. However, if the scan quality is really low there can be typos as Text & Numbers can not be identified correctly due to the bad quality of the image."
  },
{
    question:"How does the output of docutize.ai look like?", answer:"The output is a downloadable .xls, .csv or .txt file. Your selections will be ordered like in Table. Each Column represents a Selection with all according Values in this column. You can use the 'Preview' function to see your results before you download."
  }
];

const FAQ = () => {
  return (
    <section id="faq" className="mt-32" style={{ maxWidth: '1200px', margin: '0 auto', padding: '0 36px' }}>
      <h2 className="pt-20 text-center text-[black] font-medium text-[35px]">Frequently asked Questions</h2>
      <p className="my-0 font-medium text-center text-[#111] xl:text-[20px] lg:text-[18px] md:text-[16px] text-[20px] pb-5">Common questions and clear answers</p>
      <div className="grid grid-cols-1 md:grid-cols-2 gap-6 md:gap-12 mt-14">
        {FAQs.map((faq, index) => (
          <div key={index}>
            <h3 className="text-18px font-medium">{faq.question}</h3>
            <p className="font-normal text-gray-600 mt-2">{faq.answer}</p>
          </div>
        ))}
      </div>
      <div className="my-20">
      <h3 className="text-18px font-medidum">
          If you have any other questions, use the Chat, <a style={{color: '#0029FF'}} href="https://calendly.com/docutizeai/30min" target="_blank">Schedule a Call</a> or 
          email to <a style={{color: '#0029FF'}} href="mailto:support@docutize.ai">support@docutize.ai</a>
          </h3>
          </div>
    </section>
  );
};

export default FAQ;
