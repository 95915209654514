import React, { useState } from "react";
import Hamburger from "../assets/hamburgerMenuIcon.png";
import CloseIcon from "../assets/closeMenuIcon.png";
import Button from "./Button";
import { useNavigate } from "react-router-dom";
import useGoogleAnalyticsTracker from "../utils/customHooks/googleAnalytics";

const Navbar = () => {
  const [showMenuIcon, setShowMenuIcon] = useState(true);

  const navigate = useNavigate();

  const googleAnalyticsTracker = useGoogleAnalyticsTracker("NavBar");

  const handleMenuIcon = () => {
    setShowMenuIcon((prevState) => !prevState);
  };

  const onClickHandler = (e) => {
    googleAnalyticsTracker("Sign up", "Navbar sign up button");
    navigate("sign-up");
  };

  return (
    <nav className="xl:max-w-6xl lg:max-w-4xl md:max-w-2xl  m-auto px-4 py-[16px]">
      <div className="">
        {/* Mobile View */}
        <div id="mobile-menu">
          <div className="flex justify-between items-center">
            {/* Mobile Logo Begins */}
            <div className="lg:hidden">
              <a href="/">
                <img className="w-[150px]" src="/logo.svg" alt="logo" />
              </a>
            </div>
            {/* Mobile Logo Ends */}

            {/* Hamburger Begins */}
            {showMenuIcon && (
              <div onClick={handleMenuIcon} className="p-4 lg:hidden">
                <img
                  src={Hamburger}
                  alt="Hamburger Icon"
                  width={45}
                  height={45}
                />
              </div>
            )}
            {/* Hamburger Ends */}

            {/* Close Begins */}
            {!showMenuIcon && (
              <div onClick={handleMenuIcon} className="p-4 lg:hidden">
                <img src={CloseIcon} alt="Close Icon" width={45} height={45} />
              </div>
            )}
            {/* Close Ends */}
          </div>

          {/* Mobile Menu Begins */}
          {!showMenuIcon && (
            <div className="min-h-screen lg:hidden">
              <ul className="mt-8 flex flex-col justify-center items-center font-semibold text-[#111]">
                <li className="text-[#63778c] font-normal tracking-wide text-[16px] w-full py-[22px]">
                  <a href="/pricing">Pricing</a>
                </li>
                <li className="text-[#63778c] border-b-2 border-slate-200 font-normal tracking-wide text-[16px] w-full py-[22px]">
                  <a href="/faq">FAQs</a>
                </li>
                <li className="flex w-full flex-col items-center mt-10 ">
                  <div className="my-4 w-full">
                    <button type="button" className="text-[#fff] bg-[#0029ff] py-2 rounded-lg border w-full font-medium tracking-wide text-[16px]">
                      <a className="w-full" href="https://app.docutize.ai/sign-up">
                        Create Account
                      </a>
                    </button>
                  </div>
                  <div className="mb-4 w-full">
                    <button type="button" className="text-[#63778c] border py-2 rounded-lg w-full font-medium tracking-wide text-[16px]">
                      <a href="https://app.docutize.ai/sign-in">Log in</a>
                    </button>
                  </div>
                </li>
              </ul>
            </div>
          )}
          {/* Mobile Menu Ends */}
        </div>
        {/* Mobile View Ends */}

        <div className="flex items-center justify-between">
          <div className="hidden lg:block">
            <div className="flex items-center justify-between">
              {/* Desktop logo Begins */}
              <div>
                {/* <img
                src={Logo}
                alt="Desktop arithmo logo"
                width={140}
                height={50}
              />
               */}
                {/* Desktop Logo begins */}
                <a href="/">
                  <img className="w-[150px]" src="/logo.svg" alt="logo" />
                </a>
                {/* Desktop Logo ends */}
              </div>
              {/* Desktop logo Ends */}

              {/* Desktop Menu Item Begins */}
              <div className="hidden lg:block">
                <ul className="flex items-center xl:text-[16px] lg:text-[14px] md:text-[11px] text-[14px] font-semibold text-[#111] mt-1">
                  <li className="text-[#63778c] font-medium tracking-wide  text-[15px] xl:ml-[40px] lg:ml-[19px] md:ml-[15px] ml-[40px]">
                  <a href="/#usecases">Use cases</a>
                  </li>

                  <li className="text-[#63778c] font-medium tracking-wide  text-[15px] xl:ml-[40px] lg:ml-[19px] md:ml-[15px] ml-[40px] ">
                  <a href="/#howitworks">How it works</a>
                  </li>

                  <li className="text-[#63778c] font-medium tracking-wide  text-[15px] xl:ml-[40px] lg:ml-[19px] md:ml-[15px] ml-[40px]">
                    <a href="/pricing">Pricing</a>
                  </li>
                  <li className="text-[#63778c] font-medium tracking-wide  text-[15px] xl:ml-[40px] lg:ml-[19px] md:ml-[15px] ml-[40px]">
                    <a href="/faq">FAQs</a>
                  </li>

                </ul>
              </div>
              {/* Desktop Menu Item Ends */}
            </div>
          </div>

          {/* Sign in, Sign up Button Begins */}
          <div className="hidden lg:block">
            <ul className="flex items-center">
              <li className="font-semibold">
                <button type="button" className="text-[#63778c] font-medium tracking-wide xl:text-[16px] lg:text-[14px] md:text-[12px] text-[14px]">
                  <a href="https://app.docutize.ai/sign-in">Log in</a>
                </button>
              </li>
              <li className="text-[#63778c] font-medium tracking-wide  text-[15px] xl:ml-[40px] lg:ml-[40px] md:ml-[15px] ml-[40px">
                <a href="https://app.docutize.ai/sign-up">
                  <Button text="Sign Up Free" />
                </a>
              </li>
            </ul>
          </div>
        </div>
        {/* Sign in, Sign up Button Ends onClick={onClickHandler}*/}
      </div>
    </nav>
  );
};

export default Navbar;
