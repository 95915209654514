import { useEffect, useState } from "react";
import Button from "../../components/Button";
import { EMAIL_REGEX } from "../../constants/regex";
import requestHandler from "../../utils/request/requestHandler";
import { useLocation } from 'react-router-dom';

import checkicon from '../../assets/check.svg'
import Purchasing from '../../assets/Hero_RAG.png';
import user3 from '../../assets/Pers3.png';


import XIcon from '@mui/icons-material/X';
import LinkedInIcon from '@mui/icons-material/LinkedIn';
import YouTubeIcon from '@mui/icons-material/YouTube';

import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import KeyboardArrowUpIcon from '@mui/icons-material/KeyboardArrowUp';
import ArrowRightAltIcon from '@mui/icons-material/ArrowRightAlt';
import picture from '../../assets/ready.svg'


const RAG = () => {
    const [email, setEmail] = useState('');
    const [emailValid, setEmailValid] = useState(true);
    const [loading, setLoading] = useState(false);
    const [success, setSuccess] = useState(false);
    const location = useLocation();
  
    const onEmailChange = (e) => {
      e.preventDefault();
      setEmail(e.target.value);
    };
  
    useEffect(() => {
      const getEmailFromURL = () => {
        const searchParams = new URLSearchParams(location.search);
        const emailParam = searchParams.get('email');
        if (emailParam) {
          setEmail(emailParam);
        }
      };
  
      getEmailFromURL();
    }, [location.search]);
  
    const handleEmailChange = (e) => {
      setEmail(e.target.value);
    };
  
    const onClickHandler = async (e) => {
      e.preventDefault();
      if (EMAIL_REGEX.test(email)) {
        setEmailValid(true);
        setLoading(true);
        const results = await requestHandler({
          url: "https://api.getwaitlist.com/api/v1/waiter",
          requestOptions: {
            method: "POST",
            headers: { "Content-Type": "application/json" },
          },
          data: {
            email,
            api_key: process.env.REACT_APP_WAITLIST_KEY,
          },
        });
        if (results) {
          if (results.priority) setSuccess(true);
        }
        setLoading(false);
      } else {
        setEmailValid(false);
      }
    };


    const [openState, setOpenState] = useState(false);
    const [openState1, setOpenState1] = useState(false);
    const [openState2, setOpenState2] = useState(false);
    const [openState3, setOpenState3] = useState(false);
    const [openState4, setOpenState4] = useState(false);

    const toggleOpenState = () => {
        setOpenState(!openState)
    };
    const toggleOpenState1 = () => {
        setOpenState1(!openState1)
    };
    const toggleOpenState2 = () => {
        setOpenState2(!openState2)
    };
    const toggleOpenState3 = () => {
        setOpenState3(!openState3)
    };
    const toggleOpenState4 = () => {
        setOpenState4(!openState4)
    };
  return (
    <section>
      
      
      <div className="xl:max-w-6xl lg:max-w-4xl md:max-w-2xl max-w-6xl  m-auto px-4 py-8">
      <div className="pb-5 border-b-2"><a href="/knowledge-management">
                  <img className="w-[150px]" src="/logo.svg" alt="logo" />
                  </a>
                  </div>
      <div className="mt-8 bullets ">
                                   <div>
                                   <div className='mt-5 flex flex-col lg:flex-row justify-between items-center gap-10'>
                            <div className='text-[#2C374F] lg:w-[50%] pr-[10px]'>
                            <h2 className="mb-[8px] xl:mb-[8px] lg:mb-[6px] md:mb-[2px] font-semibold tracking-wider text-[#0029ff] text-[16px] ">
            #1 AI KNOWLEDGE MANAGEMENT CHAT
          </h2>
                                <h2 className='font-medium text-[24px]'>
                                Chat with your Business Knowledge
                                </h2>
                                <p className='font-normal mt-3 text-[16px]'>
                                Empower your team with instant access to critical business information using our state-of-the-art AI Chatbot to retrieve data from your documents like
                                </p>
                                <ul className='font-normal mt-3 text-[16px]'>
                                    <li className='flex gap-2 items-center'>
                                        <img src={checkicon} alt="" />

                                        <span>
                                            <span className='font-medium'>Word Documents</span>
                                        </span>
                                    </li>
                                    <li className='flex gap-2 items-center'>
                                        <img src={checkicon} alt="" />

                                        <span>
                                            <span className='font-medium'>PDFs</span>
                                        </span>
                                    </li>
                                    <li className='flex gap-2 items-center'>
                                        <img src={checkicon} alt="" />

                                        <span>
                                            <span className='font-medium'>Power Points</span>
                                        </span>
                                    </li>
                                    <li className='flex gap-2 items-center'>
                                        <img src={checkicon} alt="" />

                                        <span>
                                            <span className='font-medium'>Emails</span>
                                        </span>
                                    </li>                                    
                                </ul>
                            </div>
                            <div className='lg:w-[50%] '>
                                <div className="bg-[#0039FF] frame-container shadow-xl border border-slate-200 p-2 rounded-xl">
                                    <img className='w-[100%]' src={Purchasing} alt="purchase" />
                                </div>
                            </div>
                        </div>
                        <div className="xl:pt-20 pt-10">
                            
                            
                            
                            
                        <div className="flex flex-col items-center justify-center mb-14 py-8 gap-4 2xl:mb-2 text-center rounded-xl bg-[#F1F3FA] shadow-xl border border-slate-200">
        <div><h3 className="font-medium  xl:text-[34px] lg:text-[30px] md:text-[26px] text-[30px]">Let us contact you with more information</h3></div>
        <div className="flex flex-col md:flex-row items-center justify-center gap-5">
        <form onSubmit={onClickHandler}>
        <div className="flex gap-4 items-center sm:flex-row flex-col">
            <input
              onChange={onEmailChange}
              className="px-2 border-2 border-[#e6e7ec] md:py-2 py-1 md:max-w-[300px] rounded-md"
              style={{ borderRadius: '5px' }}
              type="email"
              placeholder="Your Work Email*"
              value={email}
              name="email"
              id="email"
            />
            {!emailValid && (
              <p className="text-left text-xs  text-[#ff0000]">
                Enter a valid Email
              </p>
            )}
          
            <Button type="submit" isLoading={loading} text="Get in Touch" />
          </div>
          {success && (
            <div
              className="bg-teal-100 rounded-b text-teal-900 px-4 py-3"
              role="alert"
            >
              <div className="flex center">
                <div>
                  <p className="text-left text-xs">
                  Thank you for your interest! We will contact {email} with more information.
                  </p>
                </div>
              </div>
            </div>
          )}
        </form>
      </div>
    </div>
                            
                            
                        </div>
                        <div className="feedback lg:w-[50%] mt-10 mx-auto">
                            <p className='text-justify'>
                            "Our experience with docutize AI has been phenomenal. It has revolutionized the way we access and utilize our business documents, saving us countless hours and significantly improving our efficiency."
                            </p>
                            <div className="mt-5 flex justify-center">
                                <img className='w-[40px] h-[40px] rounded-full' src={user3} alt="user" />
                                <div className="ml-[10px]">
                                    <h6 className='font-medium text-[14px]'>
                                        Anna F.
                                    </h6>
                                    <span className='font-medium text-[13px]'>
                                        CEO                           
                        
                                    </span>
                                </div>
                            </div>
                        </div>
                    </div>
                    </div>
                    </div>

      <div className=" lg:pt-15 mb-12 ">
      <div id='features' className='safety xl:max-w-6xl lg:max-w-4xl md:max-w-2xl m-auto px-4 py-0 lg:pt-10'>
            <div className="flex flex-col">
                <div className='text-center mb-10' >
                    <h4 className='my-[8px] font-semibold text-center text-[#0029ff]  text-[16px]'>FEATURES</h4>
                    <h1 className='mb-[15px] font-medium text-center text-slate-800  md:text-[24px]  text-[22px]'>Advanced AI Knowledge Management Chatbot</h1>
            </div>
                <div className="features px-5 py-4 lg:px-12 lg:py-10 rounded-xl bg-[#F1F3FA] shadow-xl border border-slate-200">
                    <div className='lg:flex mt-5 gap-10'>
                        <div className="mb-5 md:mb-3 w-full lg:w-1/3">
                            <h4 className='font-semibold flex gap-2 mb-2 text-slate-800'>
                                <img src={checkicon} alt="" />
                                Multi-Language Support
                            </h4>
                            <p className='font-normal pl-2 text-[16px] text-slate-700'>
                            The AI Chatbot offers multi-language support, ensuring accessibility and convenience.
                            </p>
          </div>

                        <div className="mb-5 md:mb-3 w-full lg:w-1/3">
                            <h4 className='font-semibold flex gap-2 mb-2 text-slate-800'>
                                <img src={checkicon} alt="" />

                                RAG
                            </h4>
                            <p className='font-normal pl-2 text-[16px] text-slate-700'>
                            Retrieval-Augmented Generation (RAG) combines information retrieval and text generation, enabling the AI to find relevant data from documents and generate accurate, context-aware responses.
                            </p>
          </div>

                        <div className="mb-5 md:mb-3 w-full lg:w-1/3">
                            <h4 className='font-semibold flex gap-2 mb-2 text-slate-800'>
                                <img src={checkicon} alt="" />

                                LLM
                            </h4>
                            <p className='font-normal pl-2 text-[16px] text-slate-700'>
                            A Large Language Model (LLM) is an advanced AI that understands and generates human-like text, capable of processing and responding to complex queries with high accuracy.
                            </p>
            </div>
          </div>
                    <div className="lg:flex lg:mt-5 gap-10">

                        <div className="mb-5 md:mb-3 w-full lg:w-1/3">
                            <h4 className='font-semibold flex gap-2 mb-2 text-slate-800'>
                                <img src={checkicon} alt="" />

                                Easy to use
                            </h4>
                            <p className='font-normal pl-2 text-[16px] text-slate-700'>
                            No technical knowledge required. We set your Knowledge Management Chatbot up for you. It is as easy as chatting with your colleagues.
                            </p>
                        </div >

                        <div className="mb-5 md:mb-3 w-full lg:w-1/3">
                            <h4 className='font-semibold flex gap-2 mb-2 text-slate-800'>
                                <img src={checkicon} alt="" />

                                OCR Engine
                            </h4>
                            <p className='font-normal pl-2 text-[16px] text-slate-700'>
                            Optical Character Recognition (OCR) is a technology used to get machine printed Letters and Numbers from scanned documents.
                            </p>
          </div>

                        <div className="mb-5 md:mb-3 w-full lg:w-1/3">
                            <h4 className='font-semibold flex gap-2 mb-2 text-slate-800'>
                                <img src={checkicon} alt="" />

                                GDPR Compliant
                            </h4>
                            <p className='font-normal pl-2 text-[16px] text-slate-700'>
                            Your data's safety is our priority. We are using secure data transmission & trusted cloud providers to ensure the European Union GDPR.
                            </p>
            </div>
          </div>
            </div>
            </div>
          </div>
      </div>
      <div className="faq lg:pt-18 mb-12 ">
      <div className='faq xl:max-w-6xl lg:max-w-4xl md:max-w-2xl mb-28 m-auto px-4 py-0'>
            <div className="lg:flex justify-between items-baseline">
                <div className='lg:w-1/2 pr-[50px]'>
                    <h4 className='text-[#0029ff] font-semibold text-[16px]'>FAQs</h4>
                    <h1 className='text-slate-800 font-medium mb-5 md:text-[24px]  text-[22px] '>Frequently asked questions</h1>
                </div>
                <div className="lg:w-1/2">
                    <details className='text-[18px]'>
                        <summary onClick={toggleOpenState} className='list-none flex justify-between items-center mt-6 gap-[10px] cursor-pointer text-left font-medium text-lg text-slate-800'>
                        What is a Retrieval-Augmented Generation (RAG) system?
                            {openState ? <KeyboardArrowUpIcon /> : <ExpandMoreIcon />}
                        </summary>
                        <p className='text-slate-800 font-normal text-[16px] tracking-wide mt-4'>
                        A Retrieval-Augmented Generation (RAG) system enhances the capabilities of an AI chatbot by combining information retrieval with text generation. It works by first retrieving relevant information from a collection of business documents and then using that information to generate a coherent and contextually appropriate response. This allows the chatbot to provide more accurate and detailed answers by leveraging the vast amount of data stored in business documents.
                        </p>
                    </details>
                    <details className='text-[18px]'>
                        <summary onClick={toggleOpenState1} className='list-none  flex justify-between items-center mt-6 gap-[10px] cursor-pointer text-left font-medium text-lg text-slate-800'>
                        How does the AI Chatbot work?
                            {openState1 ? <KeyboardArrowUpIcon /> : <ExpandMoreIcon />}
                        </summary>
                        <p className='text-slate-800 font-normal text-[16px] tracking-wide mt-4'>
                        To use the AI Chatbot for finding specific information, simply type your question or the topic you're interested in into the chat interface. The chatbot will then search through the relevant business documents and provide you with a concise and accurate answer based on the retrieved information. You can ask about policies, procedures, product details, or any other business-related topic.
                        </p>
                    </details>

                    <details className='text-[18px]'>
                        <summary onClick={toggleOpenState2} className='list-none  flex justify-between items-center mt-6 gap-[10px] cursor-pointer text-left font-medium text-lg text-slate-800'>
                        What types of business documents can be used?
                            {openState2 ? <KeyboardArrowUpIcon /> : <ExpandMoreIcon />}
                        </summary>
                        <p className='text-slate-800 font-normal text-[16px] tracking-wide mt-4'>
                        The AI Chatbot can retrieve information from a wide variety of business documents, including but not limited to company policies, procedure manuals, training materials, product specifications, meeting minutes, and internal reports. Essentially, any document that contains valuable business information can be used as a source for the chatbot.
                        </p>
                    </details>

                    <details className='text-[18px]'>
                        <summary onClick={toggleOpenState3} className='list-none  flex justify-between items-center mt-6 gap-[10px] cursor-pointer text-left font-medium text-lg text-slate-800'>
                        Is the information up-to-date?
                            {openState3 ? <KeyboardArrowUpIcon /> : <ExpandMoreIcon />}
                        </summary>
                        <p className='text-slate-800 font-normal text-[16px] tracking-wide mt-4'>
                        The AI Chatbot is designed to regularly index and update its database with the latest business documents. This ensures that the information it retrieves and uses for generating responses is current. Additionally, if there are any updates or new documents added, the chatbot will incorporate those changes into its knowledge base, maintaining the accuracy and relevance of its answers.
                        </p>
                    </details>

                    <details className='text-[18px]'>
                        <summary onClick={toggleOpenState4} className='list-none  flex justify-between items-center mt-6 gap-[10px] cursor-pointer text-left font-medium text-lg text-slate-800'>
                        Is docutize AI secure and confidential?
                            {openState4 ? <KeyboardArrowUpIcon /> : <ExpandMoreIcon />}
                        </summary>
                        <p className='text-slate-800 font-normal text-[16px] tracking-wide mt-4'>
                        Yes, the information retrieved by the AI Chatbot is secure and confidential. The system employs advanced security measures to protect sensitive business information. Access to the chatbot and the documents it uses is restricted to authorized personnel only. Additionally, all interactions with the chatbot are encrypted to prevent unauthorized access and ensure data privacy.

These FAQs provide a clear understanding of how the AI Chatbot functions, its capabilities, and the security measures in place to protect business information.
                        </p>
                    </details>
                </div>
            </div>
        </div>
      </div>

      <div className="bg-gradient-to-r from-teal-600 via-purple-800 to-pink-700 py-28 lg:pt-18 overflow-hidden">     
      <div className='xl:max-w-6xl lg:max-w-4xl md:max-w-2xl m-auto px-4 py-0 relative'>
            <div className="lg:flex justify-between items-baseline">
                <div className='lg:w-1/2 lg:pr-[50px] text-white'>
                    <h1 className='font-bold text-[24px] md:text-[28px] lg:text-[30x] mb-6'>
                        Ready for your AI Chatbot?
                    </h1>
                    <p className='font-medium'>See how an AI Knowledge Management Chatbot works.</p>
                    <div className="mt-10 flex gap-3">
                    <a href='https://calendly.com/docutizeai/30min'><button className='bg-white text-[14px] font-semibold text-black px-4 py-3 rounded-lg'>Book a Demo</button></a>
                    </div>
                </div>
                <div className="lg:w-1/2">
                    <img className='hidden lg:block lg:absolute lg:mt-0 mt-10 -right-7-0 -top-20 w-[100%] lg:pt-10' src={picture} alt="docs" />
                </div>
            </div>
        </div>
        </div>



        <article className="mx-auto max-w-6xl">
        <div className="flex flex-col justify-between">
          <article>
            <div className="p-8 flex flex-col justify-between items-start flex-wrap md:flex-row xl:text-[17px] lg:text-[16px] md:text-[15px] ">
              <div className="text-slate-700  lg:w-2/6">
                <div>
                  <a href="/knowledge-management">
                    <img className="w-[180px]" src="/logo.svg" alt="logo" />
                  </a>
                </div>
                <ul className='my-4'>
                  <li className="mt-4 text-[14px]  text-slate-700 font-medium">
                    Our <span className='text-[#0029ff]'>AI Knowledge Management Chatbot</span> enables Businesses to get information in quickly.
                  </li>
                  <li className=" mt-4 text-[14px] text-slate-700 font-medium">
                    Using AI, we’re radically changing the process of data retrieval, making it scalable and affordable.
                  </li>
                </ul>
                {/* <div className=" my-7 flex justify-evenly items-center gap-6">
                  <a href="/">
                    <img src="https://assets-global.website-files.com/61dc0796f359b6145bc06ea6/63c908d4cf6b6c9275f131f9_21972-312_SOC_NonCPA.png" loading="lazy" alt="SOC2 compliant" className='w-[50px]' />
                  </a>
                  <a href="/">
                    <img src="https://assets-global.website-files.com/61dc0796f359b6145bc06ea6/64e87563006ccc7100d8304a_logo-cai%201.svg" loading="lazy" alt="" className='w-[100px]' />
                  </a>
                </div> */}
                {/* <p className=" mt-3 text-[13px] text-slate-700 font-light">Address.</p> */}
              </div>
              {/* Col 1 */}
              <div className="text-slate-700 py-4 lg:w-1/7">
                <div>
                  <h2 className="font-semibold text-[#0029ff]">PRODUCTS</h2>
                </div>
                <ul>
                <li className="mt-4 text-[14px] text-slate-700 hover:text-[#0029ff] font-medium">
                    <a href="https://www.docutize.ai/knowledge-management">AI Knowledge Management</a>
              </li>
                <li className="mt-4 text-[14px] text-slate-700 hover:text-[#0029ff] font-medium">
                    <a href="https://www.docutize.ai/">AI Data Extraction</a>
              </li>
                  
                </ul>
              </div>

              {/* Col 2 */}
              <div className="text-slate-700 py-4 lg:w-1/7">
                <div>
                  <h2 className="font-semibold text-[#0029ff]">CONTACT</h2>
                </div>
                <ul>                   
                  <li className="mt-4 text-[14px] text-slate-700 hover:text-[#0029ff] font-medium">
                    <a href='mailto:support@docutize.ai'>Contact Us</a>
                  </li>
                  <li className="mt-4 text-[14px] text-slate-700 hover:text-[#0029ff] font-medium">
                    <a href='https://calendly.com/docutizeai/30min' target="_blank">Book a Call</a>
                  </li>   
                </ul>
              </div>

              {/* Col 5 */}
              <div className="text-slate-700 py-4 lg:w-1/7">
                <div>
                <h2 className="font-semibold text-[#0029ff]">COMPANY</h2>
                </div>
                <ul>
                  <li className="mt-4 text-[14px] text-slate-700 hover:text-[#0029ff] font-medium">
                    <a href="https://www.docutize.ai/about-us">About</a>
                  </li>
                  <li className="mt-4 text-[14px] text-slate-700 hover:text-[#0029ff] font-medium">
                    <a href="https://www.docutize.ai/terms-and-condition">Terms & Condition</a>
                  </li>
                  <li className="mt-4 text-[14px] text-slate-700 hover:text-[#0029ff] font-medium">
                    <a href="https://www.docutize.ai/privacy-policy">Privacy Policy</a>
                  </li>
                  <li className="mt-4 text-[14px] text-slate-700 hover:text-[#0029ff] font-medium">
                    <a href="https://www.docutize.ai/cookie-policy">Cookie Policy</a>
                  </li>
                  <li className="mt-4 text-[14px] text-slate-700 hover:text-[#0029ff] font-medium">
                    <a href="https://www.docutize.ai/dpa">DPA</a>
                  </li>
                </ul>
              </div>

            </div>
          </article>
          <div className='flex justify-between border-t-2 border-slate-200 m-8 py-5'>
            <p className='font-medium text-slate-600 text-[13px]'>© 2024 docutize.ai. All rights reserved.</p>
            <div className="icons flex justify-between gap-5">
            <a href="https://twitter.com/docutizeAI" target="_blank"><XIcon /></a>
            <a href="https://www.linkedin.com/company/docutize-ai" target="_blank"><LinkedInIcon /></a>
            <a href="https://www.youtube.com/@KoEu-we4of/featured" target="_blank"><YouTubeIcon /></a>
            </div>
          </div>
        </div>
      </article>

      {/*<div className="bg-gradient-to-r from-teal-600 via-purple-800 to-pink-700 py-28 lg:pt-18 overflow-hidden">
        <TryAIGenerator />
      </div>*/}
    </section>
  );
};

export default RAG;
