import React from 'react';
import { PricingPage } from "./pricing/PricingPage"

const FAQs = [

{
  question:"What is one Page?",
  answer:"One Page is a PDF A4 Page."
  },
  {
    question:"Do you offer a free plan?",
    answer:"Yes! The Free plan includes 20 PDF pages per month. You can test all features, including data export."
    },
{
  question:"What is OCR, AI Table & key-value-pair, GPT Recognition?",
  answer:"Optical Character Recognition (OCR) is a technology used for scanned documents. Using AI docutize.ai identifies Tables and key-value-pairs (E.g. 'Name: John', 'Name' is the key, and 'John' is the value.). Using GPT Large Language Models we can identify specific values from Texts (e.g. all telephone numbers in Texts)"
  },
{
  question:"Does docutize.ai support handwriting?",
  answer:"Handwriting is not included at this point for Free & Pro Plans. Enterprise Plans can include any kind of automatic identification up to your needs."
  },

{
  question:"What happens to unused pages?",
  answer:"Unused pages expire at the end of each billing period, and your account is topped up with new pages."
},
{
  question:"Do you offer discounts?",
  answer:"Yes. All our plans come with ~ 30-35% discount when you subscribe annually (equivalent to 3,5-4 months free)."
},

{
  question:"What happens once I have used all my pages?",
  answer:"You won't be able to parse data from PDF documents until the next billing period. Alternatively, you can upgrade your subscription."
},
{
  question:"How does Self-Setup work?",
  answer:"docutize.ai is a No-Code Platform designed to be used by everybody. You create a Selection Template without support. Just try it, you will be guided through by the platform."
},
{
  question:"What kind of Support is offered?",
  answer:"The Free Plan does not include any support. Pro Plans have Email Support included. Enterprise have Premium Support included that are up to your needs."
},
{
  question:"Can I change my subscription?",
  answer:"Our flexible subscription plans allow you to upgrade, downgrade or cancel your subscription at any time. Upgrades and downgrades take effect immediately. Cancellation is also possible at any time."
},
{
  question:"What payment types do you accept?",
  answer:"We accept all major credit cards, including MasterCard, Visa, American Express, Diners, and JCB. We do not accept cryptocurrency, PayPal, or bank transfers as payment methods."
},
{
  question:"How do I know it's safe to link my bank card?",
  answer:"docutize.ai never stores your bank card information. Your payment card details are encrypted and safely stored by the payment processor Stripe."
},
{
  question:"Can I get a refund?",
  answer:"We typically provide very limited refunds because you can try out all the features using our Free plan before you upgrade to a paid membership. However, if you are unsatisfied with our services, please contact us."
},
{
  question:"Can I get an invoice?",
  answer:"Yes, you will get the invoice automatically via email and can view and download it anytime in the Customer Portal at Account My Plan - Change my Plan - Billing."
},
{
  question:"Do you offer high-volume plans?",
  answer:"Yes! Contact us to discuss our high-volume pricing."
},
{
  question:"How does docutize.ai ensure the security of its platform and user data?",
  answer:"docutize.ai guarantees security through secure data transmission, trusted cloud providers, user activity logs, code reviews, and careful subprocessor selection. Your data's safety is our priority."
}
];

const Pricing = () => {
  return (
    <section>
      <PricingPage />
      <section className="m-auto px-4 py-2 max-w-[1200px]">
        <div>
        <p className="text-center">*Applicable Taxes may apply
        </p>
        </div>

        <section id="faq" className="mt-32" style={{ maxWidth: '1200px', margin: '0 auto', padding: '0 36px' }}>
      <h2 className="pt-10 text-center text-[black] font-medium  xl:text-[35px] lg:text-[24px] md:text-[24px] text-[22px]">Frequently asked Questions</h2>
      <p className="my-0 font-medium text-center text-[#111] xl:text-[20px] lg:text-[18px] md:text-[16px] text-[20px] pb-5">Common questions and clear answers</p>
      <div className="grid grid-cols-1 md:grid-cols-2 gap-6 md:gap-12 mt-14">
        {FAQs.map((faq, index) => (
          <div key={index}>
            <h3 className="text-18px font-medium">{faq.question}</h3>
            <p className="font-normal text-gray-600 mt-2">{faq.answer}</p>
          </div>
        ))}
      </div>



      <div className="my-20">
      <h3 className="text-18px font-medium">
          If you have any other questions, use the Chat, <a style={{color: '#0029FF'}} href="https://calendly.com/docutizeai/30min" target="_blank">Schedule a Call</a> or 
          email to <a style={{color: '#0029FF'}} href="mailto:support@docutize.ai">support@docutize.ai</a>
          </h3>
          </div>
          </section>
          </section>
    </section>
  );
};

export default Pricing;
