import React from 'react';
import Upload from '../../assets/blog/Upload.png';
import Selecttext from '../../assets/blog/Select-Text.png';
import Selecttable from '../../assets/blog/Select-table.png';
import Selectpage from '../../assets/blog/Select_page.png';
import GPT from '../../assets/blog/GPT.png';
import Preview from '../../assets/blog/Preview-blog.png';
import Dropdown from '../../assets/blog/Dropdown-use.png';
import Edit from '../../assets/blog/Edit-mode.png';
import Savings from '../../assets/blog/SavingSelections.png';
import Download from '../../assets/blog/advanced-download.png';
import BlogImage from '../../assets/blog/Data_Analyst.png';
import { Link } from 'react-router-dom';

const BlogPostView1 = () => {
  return (
    <section className="max-w-6xl mx-auto px-4 py-0 my-0">
      <article>
        <div className="flex flex-col items-center justify-center">
          
          <div className="mt-2">
              <h3 className="my-8 max-w-[700px] text-[32px] text-[black] font-medium pt-10 leading-10 px-4">
              Step-by-Step docutize.ai Guide
              </h3>
          </div>
          <div className="my-8 max-w-[700px] text-justify text-[18px]">
          <p className="font-medium pb-10">
          Welcome to Our Guide!
          </p>
          <p className="pb-10">
          Welcome to our <span class="text-[#0029ff] font-medium">step-by-step</span> guide designed to help you navigate docutize.ai with ease. From uploading documents to making the final edits, we'll cover it all. Don't forget to check out our <a style={{color: '#0029FF', textDecoration: 'Underline', fontWeight: 'bold'}} href="https://www.youtube.com/watch?v=vJsQs3zVyaM5">5min Tutorial Video</a> for a live demonstration.</p>
          <p className="font-medium pt- 10 pb-10">
          Uploading Your Documents
          </p>
          <div><img src={Upload} alt="Blog" width="700px" /></div>
          <p className="pt-20 pb-10">
          Start by selecting the <span class="text-[#0029ff] font-medium">language</span> of your documents from the dropdown menu. Should your documents be multilingual, you’re covered—simply select an additional language. Proceed to <span class="text-[#0029ff] font-medium">upload your PDF files</span>; docutize.ai supports multiple PDF files in one go, with up to 100 pages per upload. Keep an eye on the blue dot next to your email's first letter—it’s showing your remaining page count for the current billing cycle.</p>
          <p className="font-medium pt-20 pb-10">
          GPT for In-Text Value Extraction
          </p>
            <div>
            <img src={GPT} alt="Blog" width="700px" />
          </div>
          <p className="pt-20 pb-10">
          Next, use <span class="text-[#0029ff] font-medium">GPT</span> to identify in-text values within your documents. This feature is particularly useful at locating information, such as order numbers, that lack explicit labels. It's important to <span class="text-[#0029ff] font-medium">be precise with your GPT inputs</span> to ensure accuracy and avoid over-extraction or mismatches.</p>
          <p className="font-medium pt-20 pb-10">
          Select Page
          </p>
          <div>
          <img src={Selectpage} alt="Blog" width="700px" />
          </div>
          <p className="pt-20 pb-10">
          Your documents are displayed in a user-friendly split-screen format:</p>
          <p><span class="text-[#0029ff] font-medium">Left side:</span> View the pages of your document. Each colored Frame represents a Text or Table identified by docutize.ai. < br />
          <span class="text-[#0029ff] font-medium">Right Side:</span> Here, you'll find all identified text and table entries of this page, ready for your review. On top there are all Text entries and below all Tables.
          </p>
          <p className="font-medium pt-20 pb-10">
          Select Text
          </p>
          <div>
          <img src={Selecttext} alt="Blog" width="700px" />
          </div>
          <p className="pt-20 pb-10">
          You’ll find three columns designed for an intuitive selection process:</p><p>
          <span class="text-[#0029ff] font-medium">Selections:</span> Select the Values you want to extract by clicking the Checkbox or using the Dropdown menu. The system intelligently auto-selects similar future values.< br />
          <span class="text-[#0029ff] font-medium">Labels:</span> displays the automatic classification of the Values. You can directly edit a label if necessary.< br />
          <span class="text-[#0029ff] font-medium">Values:</span> shows the extracted content, with the option for direct editing.< br />
          </p>
          <p className="font-medium pt-20 pb-10">
          Dropdown Menu
          </p>
          <div>
          <img src={Dropdown} alt="Blog" width="700px" />
          </div>
          <p className="pt-20 pb-10">
          Initially, select data by marking the checkbox. For adding items to a category you've used before, you'll find it in the <span class="text-[#0029ff] font-medium">dropdown menu</span>. To maintain consistency and ensure correct data alignment, it’s recommended to reuse categories for items with similar labels.
          </p>
          <p className="pt-15 pb-10">
          For example, to categorize bank addresses with other addresses, select 'Address' for the 'Bank Address' label.
          </p>
          <p className="font-medium pt-20 pb-10">
          Select Table Columns
          </p>
          <div>
          <img src={Selecttable} alt="Blog" width="700px" />
          </div>
          <p className="pt-20">
          Table selection works like text selection, use checkboxes or the dropdown menu to select Table Columns. Additionally, two buttons are provided next to the 'Tables' header:
          </p>
          <p className="pb-5 pt-5">
          <span class="text-[#0029ff] font-medium">1st row Header:</span> Specifies that the first row contains table headers, with the table body commencing from the second row.< br />
          <span class="text-[#0029ff] font-medium">Transpose:</span> Since the platform defaults to horizontal selection, this function rotates the table, facilitating the selection of tables with vertical headers.
          </p>
          <p>
            On the top right you can click through the multiple Tables of the Page.
          </p>

          <p className="font-medium pt-20 pb-10 pt-20 pb-10">
          Preview & Download
          </p>
          <div>
          <img src={Preview} alt="Blog" width="700px" />
          </div>
          <p className="pt-20 pb-10">
          Use the <span class="text-[#0029ff] font-medium">Preview</span> to view your selections and check how your data is organized and ordered. Every category gets its own column, making it easy to see everything at a glance. In the far-right column, you'll find the page number from the original upload, allowing you to jump straight to that page for any necessary validations. </p>
          <p>To <span class="text-[#0029ff] font-medium">download</span>, select 'Download .xls'. For alternative formats and to save your selections for future use, choose 'Download Settings & Save Selection Model'.
          </p>
          <p className="font-medium pt-20 pb-10">
          Save Selections
          </p>
          <div>
          <img src={Savings} alt="Blog" width="700px" />
          </div>
          <p className="pt-20 pb-10">
          By selecting 'Download Settings & Save Selection Model' in the Preview or 'Download' in the menu bar, you can <span class="text-[#0029ff] font-medium">save your selection model</span>. This saves time on future documents, as selections are pre-applied.
          </p>
          <p className="font-medium pt-20 pb-10">
          Download Settings
          </p>
          
          <div>
          <img src={Download} alt="Blog" width="700px" />
          </div>
          <p className="pt-20 pb-10">
          After saving your Selection Model you get to Download Settings. </p>
          <ol style={{ listStyleType: 'decimal'}}>
          <li style={{ paddingBottom: '10px' }}>Choose the <span class="text-[#0029ff] font-medium">file format</span> (.xls, .csv, .txt).</li>
          <li style={{ paddingBottom: '10px' }}>Decide on <span class="text-[#0029ff] font-medium">intelligent merging</span> options for page consolidation.</li>
          <li style={{ paddingBottom: '10px' }}>Determine the <span class="text-[#0029ff] font-medium">number of files</span> you wish to generate.</li>
          <li style={{ paddingBottom: '10px' }}>Opt to <span class="text-[#0029ff] font-medium">include additional data information</span>.</li>
          </ol>
          <p className="font-medium pt-20 pb-10">
          Edit mode
          </p>
          <div>
          <img src={Edit} alt="Blog" width="700px" />
          </div>
          <p className="pt-20 pb-10">
          For corrections to colored frames, switch to <span class="text-[#0029ff] font-medium">Edit Mode</span>. Here, you can modify, create, or delete frames as needed, with all necessary tools readily available at the bottom.
          </p> 

          <p className="font-medium pb-10">
          Wrapping Up
          </p>
          <p className="pb-10">
          This guide has walked you through <span class="text-[#0029ff] font-medium">each step of docutize.ai</span>to understand the process. For further guidance and a dynamic walkthrough, watch our <a style={{color: '#0029FF', textDecoration: 'Underline', fontWeight: 'bold'}} href="https://www.youtube.com/watch?v=vJsQs3zVyaM5">5min Tutorial Video</a>. Happy data processing!
          </p>

          <div className="my-4 pt-10 pb-10">
                                        <h1 class="font-medium pb-10">If you have any <span class="text-[#0029ff] font-medium">questions</span>, reach us here: <span class="text-[#0029ff] font-medium">In-app Chat</span>, <a style={{color: '#0029FF', textDecoration: 'Underline', fontWeight: 'bold'}} href="mailto:support@docutize.ai">support@docutize.ai</a> or <a style={{color: '#0029FF', textDecoration: 'Underline', fontWeight: 'bold'}} href="https://calendly.com/docutizeai/30min">Schedule a meeting</a></h1>
                                        </div>         
            
          
      </div>
        </div>
      </article>
 
    </section>
  );
};

export default BlogPostView1;
